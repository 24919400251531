import React, { useEffect, useRef } from 'react';
import Link from 'next/link';
import { Facebook, Instagram, Twitter } from 'react-feather';
import { BiUserCircle } from 'react-icons/bi';
import {
    Box,
    Grid,
    Cell,
    BtnDot,
    Container,
    Type,
} from '@saladbob/sassafras'

import MobileNav from './MobileNav';
import { fetchUser } from '../../hooks/useSwr';
import {  useEnvDispatch } from '../providers/EnvProvider';
import Image from '../media/Image';

import styles from './Header.module.css';

import { Upload } from '../../types';

type Props = {
    logo?: string,
    defaultProfile?: Upload,
    nav?: React.ReactNode,
    mobileNav?: React.ReactNode,
}

const SocialButtons = () => (
    <Box align="center">
        <BtnDot
            tag="a"
            color="black"
            size="md"
            me="sm"
            href="https://www.facebook.com/gusmacker3on3"
            target="_blank"
        >
            <Facebook size="18" />
        </BtnDot>
        <BtnDot
            tag="a"
            color="black"
            size="md"
            me="sm"
            href="https://twitter.com/gusmacker3on3"
            target="_blank"
        >
            <Twitter size="18" />
        </BtnDot>
        <BtnDot
            tag="a"
            color="black"
            size="md"
            href="https://www.instagram.com/official_gusmacker/"
            target="_blank"
        >
            <Instagram size="18" />
        </BtnDot>
    </Box>
);

const Header: React.FC<Props> = ({
    logo, defaultProfile, nav, mobileNav
}) => {
    const { user, isLoading } = fetchUser();
    const ref = useRef(null);
    const envDispatch = useEnvDispatch();
    
    useEffect(() => {
        if (ref.current) {
            envDispatch({
                type: 'setHeaderHeight',
                value: ref.current.offsetHeight,
            });
        }
    }, [ref.current]);

    return (
        <Box
            bgColor="white"
            depth="lg"
            padding={['sm', 'md']}
            style={{ zIndex: 500 }}
            ref={ref}>
            <Container size="xl" align="center">
                <Grid columnGap="lg" className={styles.header}>
                    <Box>
                        <Link href="/">
                            <img
                                src={logo}
                                alt="Gus Macker Logo"
                                style={{ height: 88 }}
                                className="no-select"
                            />
                        </Link>
                    </Box>
                    <Box justify="center" className="hide-on-mobile">
                        {nav}
                    </Box>
                    <Cell align="center" justify="end" className="hide-on-mobile" minWidth="140px">
                        {isLoading && (
                            <Box me="md" align="center" display="flex">
                                <Box
                                    bgColor="neutral50"
                                    width={60}
                                    height={16}
                                    me="xs"
                                    rounded="sm"
                                />
                                <Box
                                    height={40}
                                    width={40}
                                    rounded="100%"
                                    bgColor="neutral200"
                                />
                            </Box>
                        )}
                        {!isLoading && user && (
                            <Box me="md" align="center" display="flex">
                                <Type font="control" color="neutral" me="xs">
                                    <Link href="/dashboard">
                                        {user.nickname || user.fname}
                                    </Link>
                                </Type>
                                <Link href="/dashboard">
                                    <Image
                                        image={user.image || defaultProfile}
                                        alt={user.fname}
                                        size="thumbnail"
                                        width={40}
                                        height={40}
                                        rounded="100%"
                                    />
                                </Link>
                            </Box>
                        )}
                        {!isLoading && !user && (
                            <Box me="md">
                                <Link href="/login">
                                    <Box tag="span" txtColor="neutral700" font="control" align="center" justify="center">
                                        <Type font="p" me="xs">LOGIN</Type>
                                        <BiUserCircle size="40" />
                                    </Box>
                                </Link>
                            </Box>
                        )}
                    </Cell>
                    <Cell align="center" justify="end" className="hide-on-laptop">
                        <MobileNav size="sm" btnColor="primary">
                            {mobileNav}
                            <Box padding={['lg', 0]} width="max-content" margin="auto">
                                {user && (
                                    <Box align="center" display="flex">
                                        <Link href="/dashboard">
                                            <Image
                                                image={user.image}
                                                alt={user.fname}
                                                size="thumbnail"
                                                width={64}
                                                height={64}
                                                rounded="100%"
                                            />
                                        </Link>
                                        <Type font="leader" color="white" ms="xs">
                                            <Link href="/dashboard">
                                                {user.nickname || user.fname}
                                            </Link>
                                        </Type>
                                    </Box>
                                )}
                                {!user && (
                                    <Box me="md">
                                        <Link href="/login">
                                            <Box tag="span" txtColor="white" font="control" align="center" justify="center">
                                                <BiUserCircle size={64} />
                                                <Type font="p" me="xs">LOGIN</Type>
                                            </Box>
                                        </Link>
                                    </Box>
                                )}
                            </Box>
                        </MobileNav>
                    </Cell>
                </Grid>
            </Container>
        </Box>
    );
};

export default Header;
